@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.viewFieldLabel {
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $BASE-LIGHT-TEXT-COLOR;
}

.viewFieldText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  white-space: unset;
  color: $BASE-TEXT-COLOR;
}

.collapseButton {
  color: red;
  cursor: pointer;
  white-space: nowrap;
}

.container {
  margin-bottom: 24px;
  max-width: 100%;
}
