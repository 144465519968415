@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.event-form {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 48px);
  &--content {
    padding: 30px 40px;
    @media screen and (max-width: $large) {
      padding: 30px 10px;
    }
  }
  &--display-block {
    display: block;
  }
  &--display-none {
    display: none;
  }
}
