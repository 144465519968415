@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.base-input {
  @include font-400();
  display: flex;
  flex-direction: column;
  color: $BASE-TEXT-COLOR;
  font-size: $BASE-FONT-SIZE;
  &--input-block {
    position: relative;
  }

  &--label {
    margin: 10px 0;
    opacity: 0.8;
  }
  &--textarea {
    @include form-styles();
    height: 162px;
    border-radius: 20px;
    line-height: 24px;
    overflow: auto;
    resize: none;
  }
  &--input {
    width: 100%;
    @include form-styles();
  }
  &--textarea,
  &--input {
    &-error {
      border: 1px solid $BASE-RED-COLOR;
    }
    &:focus {
      border: 1px solid $BASE-RED-COLOR_FOCUS !important;
    }
  }
  &--error {
    @include form-error;
  }

  &--readonly {
    &:focus {
      border: 1px solid transparent !important;
    }
  }

  &--icons {
    width: 18px;
    height: 12px;
    position: absolute;
    top: 13px;
    right: 20px;
    svg {
      cursor: pointer;
    }
  }
}

.required::after{
  color: $BASE-RED-COLOR;
  content: ' *';
}