@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.admin-item {
  min-width: 800px;
  border-bottom: 2px solid $BASE-BACKGROUND-COLOR;
  background-color: $BASE-WHITE-COLOR;
  &--content {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  &--column {
    padding: 26px 24px;
    width: calc(100% / 5);

    &:last-child {
      text-align: right;
    }
    &:nth-child(-n + 2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-management {
      @include flex-center();
      flex-wrap: wrap;
      text-align: center;
      gap: 22px;
    }
  }
  &--column--btn {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 200px;
  }
  &--edit-icon,
  &--delete-icon {
    cursor: pointer;
    &:hover {
      path {
        fill: $BASE-RED-COLOR;
      }
    }
  }
  &--write-email-btn {
    @include white-btn();
  }
}
