@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.img-video-form {
  &--upload-input {
    display: none;
  }
  &--upload-label {
    width: max-content;
    cursor: pointer;
    font-size: 14px;
    color: $BASE-LIGHT-TEXT-COLOR;
    &:nth-child(1) {
      margin: 10px 0;
      opacity: 0.8;
    }
  }
  &--upload-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &--carousel {
    position: relative;
    @media screen and (max-width: $large) {
      margin-left: 30px;
    }
    &--empty {
      @include flex-center();
      height: 100%;
      color: $BASE-TEXT-COLOR;
    }
    &--left-arrow {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      background-color: transparent;
    }
    &--right-arrow {
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      background-color: transparent;
    }
  }
  &--image-carousel {
    position: relative;
    margin-left: 40px;
    width: calc(100% - 80px);
    height: 200px;
    overflow: hidden;
    &--slider {
      left: 0;
      position: absolute;
      min-width: max-content;
      transition: 0.3s;
    }
  }
}
