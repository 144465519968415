@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.blog-form {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 48px);
  &--content {
    padding: 30px 40px;
    @media screen and (max-width: $large) {
      padding: 30px 10px;
    }
  }
  &--display-block {
    display: block;
  }
  &--display-none {
    display: none;
  }

  &--button-wrapper {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 8px;
  }

  &--buttons {
    display: flex;
    max-width: 120px;
    &--next {
      padding: 0 10px;
      border: 1px solid $BASE-RED-COLOR;
      background-color: transparent;
      color: $BASE-RED-COLOR;
      @media screen and (max-width: 600px) {
        width: 80px;
      }
    }

    &--next,
    &--save {
      width: 124px;

      @media screen and (max-width: 600px) {
        width: 100px;
      }
    }
  }

  &--title {
    margin-bottom: 20px;
  }
}