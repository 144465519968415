@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.date-picker {
  @include font-400();
  display: flex;
  flex-direction: column;
  color: $BASE-TEXT-COLOR;
  font-size: $BASE-FONT-SIZE;
  &--input-block {
    position: relative;
  }

  &--label {
    margin: 10px 0;
    opacity: 0.8;
  }

  &--input {
    width: 100%;
    padding: 0 18px;
    height: 40px;
    border-radius: 86px;
    border: 1px solid transparent;
    background-color: $BASE-WHITE-COLOR;
    color: $BASE-TEXT-COLOR;
  }

  &--input {
    &-error {
      border: 1px solid $BASE-RED-COLOR;
    }
    &:focus {
      border: 1px solid $BASE-RED-COLOR_FOCUS !important;
    }
  }
  &--error {
    @include form-error;
  }

  &--readonly {
    &:focus {
      border: 1px solid transparent !important;
    }
  }

  &--icons {
    width: 18px;
    height: 12px;
    position: absolute;
    top: 13px;
    right: 20px;
    svg {
      cursor: pointer;
    }
  }

  .anticon {
    background-color: $BASE-BACKGROUND-COLOR;
  }
}
