@import "src/assets/styles/variables";

.tickets-page {
  .tickets-modal {
    max-width: 1000px;
    min-height: 300px;
    &--title {
      text-align: center;
    }
    &--btn-block {
      margin-top: 100px;
      display: flex;
      gap: 20px;
    }

    .images-container--item {
      @media screen and (max-width: $large) {
        width: calc((100% / 4) - 8px);
        padding-bottom: calc((100% / 4) - 4px);
      }
    }
    .images-container--item-event-info {
      font-size: 14px;
    }
    .images-container--item-event-info--date {
      font-size: 12px;
    }
    .images-container--item-event-info-time {
      font-size: 10px;
    }
  }
}
