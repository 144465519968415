@import "src/assets/styles/variables";

.reported-posts-page {
  width: 100%;
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;

  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  &-total {
    text-align: right;
    font-size: $BASE-FONT-SIZE;
    margin-right: 200px;
    margin-bottom: 30px;
  }
}
