@import "src/assets/styles/variables";

.event-requests {
  background: $BASE-WHITE-COLOR;
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }
}
