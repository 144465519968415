@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.top-nav-bar {
  align-items: center;
  padding: 20px 0 20px 20px;
  background: white;
  min-height: calc(100vh - 228.5px);
  @media screen and (max-width: $medium) {
    min-height: unset;
  }
  &-item {
    height: 50px;
    width: 100%;
    padding-right: 20px;
    border-bottom: 1px solid #efefef;
  }

  a {
    text-decoration: none;
    color: $BASE-TEXT-COLOR;
    &:hover {
      color: $BASE-RED-COLOR;
    }
  }

  .active {
    color: $BASE-RED-COLOR;
  }

  &--open {
    min-height: 236px !important;
  }

  &--icon {
    display: none;
    @media screen and (max-width: $medium) {
      display: block;
      margin-top: 8px;
    }
  }

  &--open-icon {
    transform: rotate(180deg);
  }
}

.left-nav-bar {
  padding-right: 20px;
  border-right: 1px solid #efefef;
  width: 290px;
  @media screen and (max-width: $medium) {
    padding-right: 0;
    border-right: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;
    width: 100%;
  }
}
