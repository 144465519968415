@import "src/assets/styles/variables";

.venue-page {
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;

  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  &-actions {
    justify-content: flex-start;
    @media screen and (min-width: $small-L) {
      flex-direction: row !important;
    }

    &-btn {
      @media screen and (max-width: $medium) {
        justify-content: flex-start;
      }
    }
  }
}
