@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.img-video-item {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 10px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  &--content {
    width: 100%;
    height: 100%;
    video,
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      .image,
      .video {
        filter: brightness(0.6);
      }
      .middle {
        opacity: 1;
      }
    }
  }
  &--failed-loading {
    position: absolute;
    @include flex-center();
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(35, 35, 35, 0.54);
    color: #e63335;
  }
  &--loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(35, 35, 35, 0.54);
  }
  &--recycle-bin {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .cover {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    right: -30px;
    padding-bottom: 2px;
    transform: rotate(45.56deg);
    font-size: 9px;
    color: $BASE-WHITE-COLOR;
    background-color: $BASE-RED-COLOR;
  }
  .middle {
    width: 80px;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    opacity: 0;
    position: absolute;
    bottom: 10px;
  }
  .button {
    padding: 5px 10px;
    width: fit-content;
    border-radius: 50px;
    font-size: 9px;
    cursor: pointer;
    color: $BASE-RED-COLOR;
    background-color: $BASE-WHITE-COLOR;
  }
}
