@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.mobile-nav-bar {
  position: sticky;
  z-index: 60;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1c1c1c;
  border-bottom: 5px solid $BASE-RED-COLOR;
  height: 50px;
  padding: 0 14px 0 20px;
  &-drawer {
    &-item {
      padding-left: 20px;
    }
    .ant-drawer-header {
      display: none;
    }
    &-header {
      min-height: 50px;
      display: flex;
      align-items: center;
      max-height: 50px;
      border-bottom: 5px solid $BASE-RED-COLOR;
      padding: 0 20px;
      background: #1c1c1c;
      justify-content: space-between;
      position: sticky;
      top: 0;
      &-logo {
        width: 74px;
        svg {
          width: 100%;
          height: 30px;
        }
      }
      &-close {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
    .ant-drawer-body {
      background: $BASE-BACKGROUND-COLOR;
      padding: 0;
      background: #1c1c1c;
    }
    &-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 50px);
      &-list-info {
        background: white;
        border-bottom: 3px solid $BASE-RED-COLOR;
        padding: 10px 20px;
        h3 {
          font-size: 14px;
          @include reduceText(280px);
        }
      }

      &--item {
        position: relative;
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        height: 52px;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        &-name {
          text-transform: uppercase;
          color: white;
          display: flex;
          align-items: center;
        }
      }
      &--item-active {
        background: $BASE-RED-COLOR;
        .mobile-nav-bar-drawer-body--item-name {
          color: white;
        }
      }
      &-logout {
        text-align: left;
        height: 52px;
        text-transform: uppercase;
        color: white;
        display: flex;
        align-items: center;
        background: $BASE-RED-COLOR;
        padding-left: 20px;
        cursor: pointer;
        width: 100%;
      }
    }
  }
  &-logo {
    width: 74px;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
      height: 30px;
    }
  }

  //----------------------------
  .menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .menu-btn__burger {
    width: 26px;
    height: 3px;
    background: $BASE-RED-COLOR;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 26px;
    height: 3px;
    background: $BASE-RED-COLOR;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(-8px);
  }
  .menu-btn__burger::after {
    transform: translateY(8px);
  }
  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    visibility: hidden;
    background: transparent;
    box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg);
    visibility: visible;
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg);
    visibility: visible;
  }
}
