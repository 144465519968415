@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.base-checkbox {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  color: $BASE-TEXT-COLOR;
  font-size: 15px;
  input[type="checkbox"] {
    display: none;
  }
  input:checked + &--checked {
    svg {
      display: block;
    }
  }
  &--checked {
    @include flex-center();
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background-color: $BASE-RED-COLOR;
  }
  svg {
    path {
      fill: $BASE-WHITE-COLOR;
    }
    display: none;
  }
  &--label {
    cursor: pointer;
  }
}
