@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.discount-item {
  min-width: $medium;
  border-bottom: 2px solid $BASE-BACKGROUND-COLOR;
  &--content {
    @include table-content();
  }
  &--column {
    padding: 26px 24px;
    width: calc(100% / 3);
    span {
      width: 100% !important;
      @include ellipsis-text();
    }
  }
  &--edit-icon,
  &--delete-icon {
    cursor: pointer;
    width: 20px;
    &:hover {
      path {
        fill: $BASE-RED-COLOR;
      }
    }
  }
}
