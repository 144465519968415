@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.settings-view {
  &--title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    color: $BASE-RED-COLOR;
  }
  &--content {
    padding: 30px 40px;
    background-color: $BASE-WHITE-COLOR;
  }
  &--input-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 85px;
  }
  &--name-email-block {
    margin-top: 16px;
    width: 360px;
  }
  &--password-block {
    width: 405px;
    border: 2px solid $BASE-BACKGROUND-COLOR;
    border-radius: 10px;
    padding: 16px;
  }

  &--btn-block {
    width: 100%;
    text-align: center;
  }
  &--btn {
    width: 220px;
    margin-top: 20px;
  }
}
