@import "src/assets/styles/variables";

.admins-history-page {
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;

  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  &-actions {
    &-btn {
      @media screen and (max-width: $medium) {
        justify-content: flex-start;
      }
    }
  }
}
