.event-color-option {
  outline: 2px solid #CBCBCB;
  border: 2px solid #FFF;
  border-radius: 100%;
  width: 24px;
  height: 24px;

  &-container {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }

  &-active {
    outline: 2px solid #61e715;
  }
}