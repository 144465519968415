@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.reported-post-item {
  display: flex;
  min-width: 920px;
  cursor: pointer;
  .avatar {
    display: flex;
    align-items: center;
    &-picture {
      min-width: 44px;
      height: 44px;
      background: #aaaaaa;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-name {
      padding-left: 9px;
      color: #2e2e2e;
    }
  }
  .report-type-info {
    color: $BASE-RED-COLOR;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: $BASE-BACKGROUND-COLOR;
    }
    &-name {
      margin-right: 30px;
      text-transform: capitalize;
    }
    &-count {
      font-style: italic;
    }
    .total-text {
      color: $BASE-TEXT-COLOR;
      margin-right: 30px;
      font-weight: 500;
    }
    .total-count {
      font-weight: 600;
      color: $BASE-TEXT-COLOR;
      font-style: italic;
    }
  }

  &--column {
    min-width: 150px;
    padding: 12px;
    width: calc(100% / 9);
    overflow: hidden;
    border-bottom: 2px solid #efefef;
    @include flex-center();
    span {
      width: 100% !important;
      @include ellipsis-text();
    }
    @media screen and (max-width: $large-l) {
      padding: 12px 6px;
    }
  }
  .absence {
    color: rgba(57, 57, 57, 0.38) !important;
  }
}
