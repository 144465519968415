@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.venue-info {
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: rgba(239, 239, 239, 0.5);
  padding: 24px;
  gap: 40px;
  @media screen and (max-width: 1124px) {
    align-items: center;
    flex-direction: column;
    gap: 0;
  }

  &--title {
    font-size: 14px;
    margin-bottom: 12px;
    @media screen and (max-width: $large) {
      font-size: 10px;
    }
  }

  &--left-block {
    width: 100%;
    font-size: 14px;
  }

  &--data-desc {
    height: 132px !important;
    span {
      @include line-clamp(6);
    }
  }

  &--days-title-block {
    text-align: center;
    margin-bottom: 8px;
  }

  &--time-title-block {
    width: 50%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }

  &--right-block {
    width: 100%;
    min-height: calc(100% - 26px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--working-hour {
    @include flex-center;
    gap: 32px;
    min-height: 40px;
    margin-bottom: 26px;
    @media screen and (min-width: 1120px) {
      justify-content: left;
      gap: 18px;
    }
    @media screen and (min-width: $medium) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  &--time-block {
    display: flex;
    gap: 32px;
    @media screen and (max-width: $small-L) {
      flex-wrap: wrap;
      gap: 18px;
      justify-content: center;
    }
  }

  &--working-day,
  &--opening-time,
  &--closing-time {
    display: inline-block;
    text-align: center;
    padding: 8px 26px;
    border-radius: 30px;
    background-color: $BASE-WHITE-COLOR;
  }

  &--working-day {
    width: 155px;
    font-size: 20px;
    color: $BASE-RED-COLOR;
  }

  &--opening-time,
  &--closing-time {
    width: 110px;
    padding: 14px 26px;
    font-size: 12px;
  }
}
