@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.write-email-form {
  &--email-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 14px;
    margin-bottom: 30px;
    color: $BASE-TEXT-COLOR;
  }

  &--textarea {
    width: 100%;
    height: 186px;
    border-radius: 20px;
    padding: 12px 26px;
    resize: none;
    background-color: $BASE-BACKGROUND-COLOR;
    color: $BASE-TEXT-COLOR;
  }

  &--error {
    @include form-error;
  }

  &--share-btns {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    gap: 30px;
    .base-button {
      width: 160px;
    }
    &--with {
    }
    &--all {
      @include white-btn();
    }
  }

  &--search-block {
    max-width: 380px;
  }
  &--table-block {
    max-height: 250px;
    overflow: auto;
    @include scroll-bar();
  }

  &--submit-block {
    text-align: center;
    margin-top: 30px;
    .base-button {
      width: 84px;
    }
  }
}
