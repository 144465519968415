@import "src/assets/styles/variables";

.sales{
  justify-content: space-between;
  row-gap: 20px;
  margin-bottom: 40px;
  column-gap: 20px;
  @media screen and (max-width: $medium) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &-item{
    max-width: 300px;
    width: 100%;
    height: fit-content;
    justify-content: center;
    padding: 10px;
    background: $BASE_RED-COLOR;
    gap: 60px;
    @media screen and (max-width: $large) {
      gap: 20px;
    }
    @media screen and (max-width: $medium) {
      gap: 30px;
    }
    border-radius: 20px;
    svg{
      width: 30px;
      height: 30px;
    }
    p{
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-bottom: 6px;
      white-space: nowrap;
    }
    span{
      font-size: 15px;
      font-weight: 400;
      color: white;
    }
  }
}
