@import "src/assets/styles/variables";

.base-search {
  &-checkbox {
    display: none;
  }
  &--input {
    max-width: 380px;
    position: relative;
    padding: 12px 0;
    height: 40px;
    border-radius: 25px;
    transition: all 0.3s ease;
    border: 1px solid $BASE-RED-COLOR;
    input {
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 25px;
      background: none;
      color: $BASE-TEXT-COLOR;
      outline: none;
      border: none;
      padding: 0 20px 0 50px;
      font-size: $BASE-FONT-SIZE;
    }
    &-icon {
      z-index: 50;
      top: 10px;
      position: absolute;
      cursor: pointer;
      width: 60px;
      padding-left: 20px;
      border-radius: 25px 0 0 25px;
    }
  }
  #search-input {
    &:checked ~ .base-search--input {
      max-width: inherit;
      @media screen and (max-width: $medium) {
        max-width: 40px;
        border: 1px solid $BASE-RED-COLOR;
      }
    }
    &:checked ~ .base-search--input .base-search--input-icon {
      @media screen and (max-width: $medium) {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        border-radius: 25px;
        padding-left: 0;
      }
    }
  }
}
