@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.address-autocomplete {
  &--location-predictions {
    padding-top: 10px;
    border-radius: 20px;
    margin-top: 10px;
    list-style-type: none;
    z-index: 2;
    background: $BASE-BACKGROUND-COLOR;
    li {
      padding: 10px 12px;
      cursor: pointer;
      &:hover {
        color: $BASE-RED-COLOR;
      }
    }
  }
  &--label {
    margin: 10px 0;
  }
  &--input {
    padding: 0 18px;
    line-height: 38px;
    border-radius: 86px;
    border: 1px solid transparent;
    background: $BASE-BACKGROUND-COLOR;
    color: $BASE-TEXT-COLOR;
    &-error {
      border: 1px solid $BASE-RED-COLOR;
    }
    &:focus {
      border: 1px solid $BASE-RED-COLOR_FOCUS !important;
    }
  }
  &--error {
    @include form-error;
  }
}
