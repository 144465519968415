@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
.hashtag-form {
  &-title {
    font-size: 18px !important;
    @include main-title;
    @media screen and (max-width: $medium) {
      font-size: 16px !important;
    }
  }
  &-add-section {
    display: flex;
    margin-bottom: 42px;
    @media screen and (max-width: $medium) {
      flex-direction: column;
    }
    &-input {
      width: 266px;
      @media screen and (max-width: $medium) {
        width: 100%;
      }
    }
    &-button {
      margin-left: 30px;
      @media screen and (max-width: $medium) {
        width: 100%;
        margin: 4px 0 0 0;
      }
      button {
        padding: 0 24px;
      }
      background: white;
    }
    &-all {
      &-title {
        font-size: 18px !important;
        @include main-title;
        margin-bottom: 31px;
        @media screen and (max-width: $medium) {
          font-size: 16px !important;
        }
      }
      &-search {
        display: flex;
        &-input {
          width: 266px;
        }
      }
      &-hashtags {
        display: block;
      }
    }
    &-bottom {
      @include flex-center();
      margin: 20px auto;
      &-button {
        width: inherit;
        padding: 0 20px;
      }
      @media screen and (max-width: $medium) {
        width: 100%;
      }
    }
  }
}
