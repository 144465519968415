@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.subTitle {
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: $GRAY-COLOR;
  text-transform: uppercase;
  margin-bottom: 14px;
}
