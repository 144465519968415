@import "src/assets/styles/variables";

.discounts-page {
  &-search-wrapper {
    @media screen and (max-width: $large) {
      flex-direction: column;
    }
  }
  &-search {
    @media screen and (max-width: $large) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}