@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.hashtags-list {
  position: relative;
  margin-top: 20px;
  &--content {
    //height: 220px;
    overflow: auto;
    @include scroll-bar;
  }

  svg {
    path {
      fill: $BASE-RED-COLOR;
    }
  }

  &--title {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
  }

  &--hashtag-block {
    margin: 10px;
    display: inline-block;
    &:hover {
      .hashtags-list--remove-icon {
        display: block;
      }
    }
  }

  &--hashtag {
    margin-right: 10px;
    position: relative;
    padding: 12px 24px;
    height: 44px;
    font-size: 14px;
    color: $BASE-TEXT-COLOR;
    border: 1px solid $BASE-TEXT-COLOR;
    border-radius: 171px;
  }

  &--hashtag--selectable {
    cursor: pointer;
  }

  &--hashtag--selected {
    color: $BASE-RED-COLOR;
    border-color: $BASE-RED-COLOR;
  }

  &--remove-icon {
    display: none;
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
  }

  &--load-more {
    margin-top: 10px;
    text-align: center;
    &--btn {
      cursor: pointer;
      color: $BASE-TEXT-COLOR;
      background-color: transparent;

      &:hover {
        color: $BASE-RED-COLOR;
      }
    }
  }

  .baseLoader {
    width: 200px;
  }
}
