@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.table-actions-dropdown {
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      button {
        width: 100% !important;
      }
    }
  }
}

.table-actions-dropdown-container {
  display: none;

  @media screen and (max-width: $large) {
    display: block;
  }
}

.table-actions {
  display: flex;

  @media screen and (max-width: $large) {
    display: none;
  }
}