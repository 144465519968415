@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.base-pagination-wrapper {
  width: 50%;
  margin: auto;
}

.base-pagination {
  .pagination {
    width: 100%;
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
  }

  .page-item .page-link {
    position: relative;
    display: block;
    margin: 0 10px;
    text-decoration: none;
  }
  .page-link {
    color: rgba(57, 57, 57, 0.46);
  }

  .page-item.active .page-link {
    font-weight: bold;
    color: $BASE-TEXT-COLOR;
  }

  .page-item.disabled .page-link {
    pointer-events: none;
    cursor: auto;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}
