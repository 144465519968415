@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.event-info {
  flex: 1;
  padding: 24px;
  font-size: 14px;
  background-color: rgba(239, 239, 239, 0.5);
  @media screen and (max-width: 1800px) {
    .view-input--value {
      width: 100%;
    }
  }
  &--color-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &--title {
    font-size: 14px;
  }

  &--data-desc {
    height: 132px !important;
    span {
      @include line-clamp(6);
      -webkit-line-clamp: 7 !important;
      overflow-wrap: break-word;
    }
  }
}
