@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.view-input {
  width: 100%;
  margin-bottom: 26px;
  &--title {
    font-size: 14px;
    @media screen and (max-width: $large) {
      font-size: 10px;
    }
  }

  &--value {
    width: 100%;
    height: 40px;
    margin-top: 12px;
    background-color: $BASE-WHITE-COLOR;
    color: $GRAY-COLOR;
    border-radius: 30px;
    padding: 13px 26px 11px;
    span {
      @include line-clamp(1);
    }
    a {
      text-decoration: none;
    }
  }
}
