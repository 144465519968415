@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.notfound {
  @include center-block();
  &--title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: $BASE-TEXT-COLOR;
  }
}
