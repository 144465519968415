@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";


.venue-viewer {
 &-header {
   @media screen and (max-width: $large) {
     flex-direction: column;
   }
 }
 &-search {
   @media screen and (max-width: $large) {
     width: 100% !important;
     max-width: 100%;
   }
 }
}