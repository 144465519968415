@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.no-data {
  // @include center-block;
  text-align: center;
  &--text {
    margin-bottom: 20px;
    font-size: 25px;
    color: $BASE-RED-COLOR;
  }
}
