@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.custom-modal {
  @include modal-style();
  &--close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &--content {
    @include center-block();
    background-color: $BASE-WHITE-COLOR;
    width: 50vw;
    min-height: 200px;
    height: max-content;
    padding: 40px;
    animation-name: example;
    animation-duration: 0.5s;
    @media screen and (max-width: $medium) {
      width: 93vw;
      padding: 18px 14px;
    }
    @media screen and (max-width: $small) {
      padding: 18px 14px;
    }
  }
  @keyframes example {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
