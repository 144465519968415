@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.venue-manager-form {
  &--content {
    max-height: 480px;
    overflow: auto;
    margin-top: 10px;
    padding: 11px 12px;
    background-color: $BASE-BACKGROUND-COLOR;
    @include scroll-bar();
  }
  &--item {
    position: relative;
    padding: 11px 16px;
    border: 1px solid $BASE-WHITE-COLOR;
    border-radius: 9px;
    margin-bottom: 10px;
  }
  &--remove-manager-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  &--btn-block {
    padding: 8px 0 42px 24px;
    color: $BASE-TEXT-COLOR;
    background-color: $BASE-BACKGROUND-COLOR;
  }
  &--save {
    width: 124px;
    margin-top: 20px;
  }
  &--btn {
    @include flex-center();
    font-weight: 500;
    justify-content: flex-start;
    gap: 16px;
    cursor: pointer;
  }
}
