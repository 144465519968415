@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.user-item {
  min-width: $medium;
  border-bottom: 2px solid $BASE-BACKGROUND-COLOR;
  &--content {
    @include table-content();
  }
  &--column {
    padding: 26px 24px;
    width: calc(100% / 3);
    span {
      width: 100% !important;
      @include ellipsis-text();
    }
  }

  &--hashtag {
    @include ellipsis-text();
  }
  .tags-column {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    color: #784949;
  }
}
