@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.error-validation {
  margin-top: 16px;
  font-size: 15px;
  color: $BASE-RED-COLOR;
}

.editor {
  margin-top: 10px;
}