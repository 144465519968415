@import "src/assets/styles/variables";

.base-button {
  width: 100%;
  height: 40px;
  text-align: center;
  border-radius: 86px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: $BASE-WHITE-COLOR;
  background-color: $BASE-RED-COLOR;
}
.outlined {
  border: 1px solid $BASE-RED-COLOR;
  background-color: transparent;
  color: $BASE-RED-COLOR;
}
