@import "react-big-calendar/lib/sass/styles";
@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.events-page {
  &-search {
    @media screen and (max-width: $large) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  &-header {
    @media screen and (max-width: $large) {
      flex-direction: column;
    }
  }
}
.calendar-view-container {
  width: 1335px;
  margin: 0 auto;
  height: 100vh;
  position: relative;

  // @include main-container-media-queries;

  .calendar-container {
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px;

    .rbc-calendar {
      height: 100%;

      .rbc-toolbar {
        width: 100%;
        background-color: #fcfcfe;
        padding: 13px 26px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        border-radius: 10px;

        .rbc-toolbar-content {
          line-height: unset;

          .arrow {
            cursor: pointer;
            padding-bottom: 5px;
            height: 27px;
          }
        }

        .rbc-toolbar-label {
          font-size: 18px;
          padding: 0 5px;
          color: #32325d;
        }

        div {
          &.empty-space {
            width: 12%;
          }
        }
      }

      .rbc-month-view {
        border: none;
        padding: 0 25px 50px;

        .rbc-header {
          padding-right: 10px;
          text-align: right;
          border-left: none;
          color: #4a4a7f;

          &.day-off {
            color: #8da1a6;
          }
        }

        .rbc-month-row {
          max-height: 140px;
          min-height: 140px;
          overflow: visible;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;

          &:last-child {
            border-bottom: 1px solid #ddd;
          }
        }
      }

      .rbc-day-bg {
        color: #8da1a6;

        &.rbc-off-range-bg {
          background-color: white;
        }
      }

      .rbc-row {
        .rbc-date-cell {
          padding-right: 12px;
          padding-top: 7px;
          pointer-events: none;

          &.rbc-off-range {
            a {
              color: #8da1a6;
              font-weight: bold;
            }
          }

          a {
            font-size: 14px;
            font-weight: bold;
            color: #32325d;
          }
        }

        .rbc-event {
          background-color: unset;
          padding-top: 25px;

          .rbc-event-content {
            // overflow: unset;

            .past-event{
              background-color: $BASE-LIGHT-TEXT-COLOR;
              font-size: 14px;
              padding: 5px;
            }

            .event {
              background-color: #ed2f31;
              // padding-top: 35px;
              font-size: 14px;
              padding: 5px;

              &.full-height {
                height: fit-content;
                border-bottom: 1px solid #e6e6e6;
                box-shadow: 0px 8px 10px rgba(198, 198, 198, 0.5);
                padding-bottom: 20px;
                background-color: white;
                margin-bottom: 50px;
              }

              div {
                position: relative;
                padding: 3px 10px;
                cursor: pointer;

                p {
                  width: 120%;
                  padding-right: 30px;
                  font-family: BryantPro-Bold, serif;
                  font-size: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  margin: 0;
                  line-height: 1.3em;
                }

                &.event-item {
                  &.initial-deposit {
                    color: #ad4de6;
                  }

                  &.inspection-period {
                    color: #4394bf;
                  }

                  &.additional-deposit {
                    color: #f6b43e;
                  }

                  &.title-search {
                    color: #83af55;
                  }

                  &.loan-approval {
                    color: #663399;
                  }

                  &.closing-date {
                    color: #ff0167;
                  }

                  &.effective-date {
                    color: #42cfc1;
                  }

                  &.contact-execution {
                    color: #42cfc1;
                  }

                  &:hover {
                    border: 1px solid #e6e6e6;
                    box-shadow: 0px 2px 10px rgba(198, 198, 198, 0.5);

                    .transactions-tool-tip,
                    .triangle {
                      display: block;
                    }
                  }

                  .transactions-tool-tip {
                    max-width: 240px;
                    display: none;
                    position: absolute;
                    text-align: center;
                    left: 109%;
                    top: -45px;
                    border: 1px solid #e6e6e6;
                    box-shadow: 0px 2px 10px rgba(198, 198, 198, 0.5);
                    border-radius: 2px;
                    background-color: white;
                    padding: 0;
                    z-index: 9;
                    margin-bottom: 30px;

                    h2 {
                      margin: 0;
                      padding: 10px 20px;
                      font-size: 14px;
                      font-weight: 500;
                      background-color: #fafafa;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }

                    a {
                      display: block;
                      text-decoration: none;
                      padding: 10px 20px;
                      margin: 0;
                      font-size: 13px;
                      font-weight: 500;
                      color: #464679;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      &:hover {
                        border: 1px solid #e6e6e6;
                        border-left: none;
                        box-shadow: 0px 2px 10px rgba(198, 198, 198, 0.5);
                        color: #0060ff;
                      }
                    }
                  }

                  .triangle {
                    display: none;
                    position: absolute;
                    background-color: white;
                    width: 21px;
                    height: 21px;
                    left: -12px;
                    top: 47px;
                    border-left: 1px solid #e6e6e6;
                    border-top: 1px solid #e6e6e6;
                    transform: rotate(-45deg);
                    z-index: 10;
                  }
                }
              }

              .see-more {
                color: #bbbbbb !important;
              }
            }
          }

          &:focus {
            outline: none;
          }
        }

        //&:not(.rbc-month-header) {
        //  height: 0;
        //}
      }
    }

    .rbc-show-more {
      color: $BASE-RED-COLOR;
      padding: 5px;
    }

    .rbc-events-container {
      display: flex;
      flex-direction: column;

      .rbc-event {
        position: relative !important;
        width: fit-content !important;
        height: fit-content !important;
        border-radius: 0 !important;
        background: $BASE-RED-COLOR;
        border: none !important;

        .rbc-event-label {
          display: none !important;
        }
      }
    }

    .rbc-time-view {
      .rbc-event {
        padding: 5px !important;
      }
    }
  }
}

.event-page {
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;

  &--container {
    position: relative;
    min-height: 140px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }
}

.rbc-overlay {
  &-header {
    font-size: 14px;
    font-family: "Roboto", sans-serif !important;
    padding: 5px 10px;
  }

  .rbc-event {
    padding: 5px;
    border-radius: 0;
    background: $BASE-RED-COLOR;
  }
}

.event-popup-content {
  max-width: 200px;
  min-width: 200px;

  &-title {
    font-size: 14px;
    @include reduceText(200px);
  }

  &-desc {
    @include reduceText(200px);
  }
}

.add-to-calendar-btn {
  .atcb-button-wrapper {
    padding: 0 !important;
  }
}
