@import "src/assets/styles/variables";

.event-location-details {
  margin-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
  .base-select,
  .base-input {
    width: 100%;
  }
}
