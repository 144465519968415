@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.ticket-form {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 48px);

  &--content {
    padding: 30px 40px;
    @media screen and (max-width: $large) {
      padding: 30px 10px;
    }
  }

  &--display-block {
    display: block;
  }

  &--display-none {
    display: none;
  }

  &--price-currency-block {
    display: flex;
    gap: 10px;
  }

  &--price-block {
    flex: 3;
  }

  &--currency-block {
    flex: 1;
  }
  .currency-select {
    max-width: 100px;
  }
  .add-early-bird-btn {
    max-width: 150px;
    width: 150px;
    margin-top: 10px;
  }
  .early-bird-inputs {
  }
}
