@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.auth-form {
  @include center-block();
  @include font-500();

  max-width: 400px;
  width: 100%;
  @media screen and (max-width: $small) {
    width: 90%;
  }

  &--logo {
    text-align: center;
    margin-bottom: 42px;
    width: 100%;
  }
  &--title {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 28px;
    letter-spacing: 0.07em;
    color: #e63335;
  }

  &--login {
    margin-top: 16px;
  }
  &--forgot-password {
    margin-top: 26px;
    a {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 0.1em;
      color: $BASE-RED-COLOR;
    }
  }

  &--expired-token-error {
    text-align: center;
    font-size: 12px;
    &--title,
    &--sub-title {
      color: $BASE-RED-COLOR;
    }
  }
}
