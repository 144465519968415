@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.advertisement-info {
  &--data-desc {
    height: 132px !important;
    span {
      @include line-clamp(6);
    }
  }
}
