@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.discount-form {
  &--block {
    display: flex;
    align-items: center;
    gap: 100px;
    @media screen and (max-width: $medium) {
      flex-direction: column;
      gap: 0;
    }
  }
  &--left-block {
    width: 100%;
  }
  &--right-block {
    width: 100%;
  }

  &--btn {
    margin-top: 20px;
    text-align: center;
    .base-button {
      width: 125px;
    }
  }

  @media screen and (max-width: $medium) {
    &--left-block,
    &--right-block {
      width: 80%;
    }
  }
}
