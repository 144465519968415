@import "src/assets/styles/mixins";

.blog-view {
  @include base-view;

  &--edit {
    @include base-view-edit-btn;
  }

  &--name {
    @include base-view-name;
  }

  &--top-block {
    @include base-view-top-block;
  }

  &--bottom-block {
    @include base-view-bottom-block;
  }
}
