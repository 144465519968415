@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.app {
  background-color: $BASE-BACKGROUND-COLOR;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

textarea,
input,
button {
  border: none;
}

.error-validation {
  text-align: center;
  font-size: 15px;
  color: $BASE-RED-COLOR;
}

.error-message {
  @include center-block();
  width: 80%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.07em;
  color: $BASE-RED-COLOR;
}

.modal-open {
  overflow: hidden;
}

.modal-input {
  background-color: $BASE-BACKGROUND-COLOR !important;
}

.password-input {
  padding-right: 40px !important;
}

.base-page-header {
  display: flex;
  gap: 30px;
  margin: 20px 0 20px 0;
  @media screen and (max-width: $medium) {
    flex-direction: column;
    justify-content: center;
  }

  &--view {
    margin-left: auto;
    display: flex;
  }

  .base-search--input {
    width: 266px;
  }
  .base-button {
    width: 140px;
  }
}

.base-page-filters {
  display: flex;
  align-items: center;
  gap: 30px;
}

.disabled-element {
  pointer-events: none;
}

.input-field-distance {
  margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.d-flex {
  display: flex;
}

.d-flex-aligned-center {
  display: flex;
  align-items: center;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-aligned-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.space-between{
  justify-content: space-between;
}
.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.c-red {
  color: #ed2f31;
}

.btn-danger {
  background-color: #1c1c1c !important;
  color: #ffffff !important;
  border: 1px solid #1c1c1c !important;
  &:hover {
    background-color: lighten(#1c1c1c, 15) !important;
    color: #ffffff !important;
    border: 1px solid #1c1c1c !important;
  }
}

// Position start
@each $val in $positions {
  .#{$val} {
    @include position($val);
  }
}
// Position end

// Margin/Padding - Start
@each $position in $positions {
  @for $i from 0 through 64 {
    @include margin($i, $position);
    @include padding($i, $position);
  }
}
// Margin/Padding - end

// Height/Width - start
@for $i from 0 through 30 {
  .h-#{$i} {
    height: $i * 8px;
  }
  .w-#{$i} {
    width: $i * 8px;
  }
}

// Flex - start
@for $i from 1 through 25 {
  .flex-#{$i} {
    flex: #{$i};
  }
}

@each $val in $displays {
  .#{$val} {
    @include display($val);
  }
}

@each $val in $directions {
  .flex-#{$val} {
    @include flex-direction($val);
  }
}

@each $val in $aligns {
  .align-#{$val} {
    @include align-items($val);
  }
}

@each $val in $justify {
  .justify-#{$val} {
    @include justify-content($val);
  }
}
