@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.advertisement-view {
  @include base-view;

  &--edit {
    @include base-view-edit-btn;
  }

  &--name {
    @include base-view-name;
  }

  &--top-block {
    @include base-view-top-block;
    padding: 24px;
    font-size: 14px;
    background-color: rgba(239, 239, 239, 0.5);
  }

  &--bottom-block {
    @include base-view-bottom-block;
  }
}
