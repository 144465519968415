@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.stepper {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $BASE-TEXT-COLOR;
  &--steps {
    display: flex;
    background: none;
  }
  &--children {
    flex: 1;
    margin: 20px 0;
    background-color: $BASE-WHITE-COLOR;
  }
  &--name {
    @media screen and (max-width: $small) {
      display: none;
    }
  }
  &--step {
    @include flex-center;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: 1px solid $BASE-TEXT-COLOR;
    transition: 0.2s;
    &-active {
      background-color: $BASE-RED-COLOR;
      color: $BASE-WHITE-COLOR;
      border: 1px solid $BASE-WHITE-COLOR;
    }
  }
  &--line {
    width: calc(100% - 10px);
    height: 1px;
    background-color: #d8d8d8;
    transition: 0.2s;
    &-active {
      background-color: $BASE-RED-COLOR;
    }
  }
  &--block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: none;
    gap: 10px;
    &:not(:last-child) {
      padding-right: 10px;
      flex: 1;
    }
    &--clickable {
      cursor: pointer;
    }
  }
  &--buttons {
    display: flex;
    gap: 16px;
    &--block-1,
    &--block-2 {
      flex: 1;
    }
    &--block-2 {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 20px;
      @media screen and (max-width: $small) {
        gap: 12px;
      }
    }
    &--back {
      width: auto;
      height: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: $BASE-TEXT-COLOR;
      cursor: pointer;
      background: none;
      &-text {
        @media screen and (max-width: $small) {
          display: none;
        }
      }
    }

    &--next {
      padding: 0 10px;
      border: 1px solid $BASE-RED-COLOR;
      background-color: transparent;
      color: $BASE-RED-COLOR;
      @media screen and (max-width: 600px) {
        width: 85px;
      }
    }

    &--next,
    &--save {
      width: 124px;

      @media screen and (max-width: 600px) {
        width: 100px;
      }
      @media screen and (max-width: $small) {
        width: 85px;
      }
    }
  }
}
