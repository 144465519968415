@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.post-view {
  @include base-view;
  &-info{
    flex-wrap: wrap;
    column-gap: 100px;
    &-right{
      flex: 1;
      row-gap: 20px;
    }
  }
}
