@import "../../assets/styles/variables";

.user-status-modal {
  max-width: 480px;
  &-body {
    &-title {
      font-size: 18px;
      color: $BASE-LIGHT-TEXT-COLOR;
    }

    &-form {
      max-width: 400px;

      textarea {
        max-width: 400px;
        max-height: 170px;
      }
    }
  }
}
