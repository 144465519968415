$BASE-TEXT-COLOR: rgba(57, 57, 57, 0.8);
$BASE-RED-COLOR: #ed2f31;
$BASE-RED-COLOR_FOCUS: rgba(230, 51, 53, 0.6);
$BASE-WHITE-COLOR: #ffffff;
$BASE-BACKGROUND-COLOR: #efefef;
$BASE-LIGHT-TEXT-COLOR: #393939;
$GRAY-COLOR: #999;
$HEADER-BACKGROUND-COLOR: #1c1c1c;

$SMALL-FONT-SIZE: 9px;
$BASE-FONT-SIZE: 14px;
$ERROR-FONT-SIZE: 15px;

$large-xl: 1920px;
$large-l: 1440px;
$large: 1024px;
$medium: 768px;
$small-L: 580px;
$small: 445px;

$MOBILE-NAVBAR-SMALL-WIDTH: 50px;
$MOBILE-NAVBAR-LARGE-WIDTH: 78px;

$positions: ("top", "left", "bottom", "right");
$activeElementBoxShadow: 0 0 4px rgba(15, 42, 141, 0.6);
$displays: (block, inline-block, inline, flex, inline-flex, hidden);

$directions: (row, row-reverse, col, col-reverse);

$aligns: (stretch, start, center, end, baseline);

$justify: (start, center, end, between, around);

$colorPrimary: #c4c4c4;
$colorGraySecondary: #595959;
$colorGrayBold: #373f41;

$selectedColor: #1890ff;
