@import "../../../../src/assets/styles/variables";

.permission-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px;
  @media screen and (max-width: $medium) {
    width: 100%;
    height: calc(100% - 50px);
    padding: 50px 8px;
  }

  &-wrapper {
    &-content {
      flex: 1;
    }
  }
}
