@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.dashboard-page {
  display: flex;
  flex-direction: column;
  padding: 0 0 35px 0;
  @media screen and (max-width: 400px){
    padding: 0;
  }
  &-content {
    display: grid;
    column-gap: 20px;
    row-gap: 40px;
    grid-template-columns: 300px 1fr 1fr;
    align-items: center;
    @media screen and (max-width: $large) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $medium) {
      grid-template-columns: repeat(1, 1fr);
    }
    &-item{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-left{
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      &-counts{
        margin-top: 50px;
        &-item{
          h2{
            font-size: 18px;
          }
          p{
            font-size: 18px;
            color: $BASE_RED-COLOR;
            font-weight: bold;
          }
        }
      }
    }
  }

  .ant-radio-group {
    display: none;
  }

  .ant-picker-calendar {
    max-width: 300px;
  }

  .ant-picker-cell-today {
    .normal-day, .custom-day {
      display: flex;
      justify-content: center;
      align-items: center;

      .event-day {
        border: 1px solid $BASE-RED-COLOR;
        border-radius: 4px;
        width: 24px;
        height: 24px;
      }

    }

    .custom-day {
      background: $BASE_RED-COLOR;
    }
  }

  .ant-picker-cell {
    .custom-day {
      display: flex;
      align-items: center;
      justify-content: center;

      .event-day {
        background: $BASE_RED-COLOR;
        width: 24px;
        height: 24px;
        color: white;
        border-radius: 4px;
      }
      .past-event-day {
        background: $BASE-LIGHT-TEXT-COLOR;
        width: 24px;
        height: 24px;
        color: white;
        border-radius: 4px;
      }
    }
  }

  .pie-chart-label {
    p {
      text-transform: capitalize;
      font-size: 12px;
      margin-bottom: 4px;
    }

    span {
      font-size: 12px;
    }
  }

  .recharts-pie-label-line {
    display: none;
  }

  .recharts-dot {
    display: none;
  }

  &-chart {
    background-color: $BASE-WHITE-COLOR;
    padding: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      padding: 10px;
    }
    &-responsive-container {
      max-width: 450px;
      width: 100%;

      @media screen and (max-width: $large) {
        max-width: initial;
      }
    }
  }

  h2 {
    font-size: 18px;
    color: $HEADER-BACKGROUND-COLOR
  }
  &-title {
    text-align: center;
    padding: 10px;
  }

  &-active-quantity {
    background-color: $BASE-WHITE-COLOR;
    min-height: 310px;
    padding: 0 20px;
  }

  &-posts-analytics {
    justify-content: space-between;
    width: 100%;
    row-gap: 35px;
    &-total {
      background-color: $BASE-WHITE-COLOR;
      min-height: 310px;
      padding: 0 20px;
      &-item {
        justify-content: space-between;
        svg {
          width: 35px;
          height: 35px;
        }

        p {
          font-size: 18px;
          color: $BASE-RED-COLOR;
          font-weight: bold;
        }
      }
    }
  }

}

.popover-event-list {
  background: white;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: $BASE-RED-COLOR;
      font-weight: bold;
    }

    .event-name {
      font-weight: normal;
      color: black;
      margin-left: 3px;
      display: inline-block;
      @include reduceText(150px);
    }

    .event-start {
      font-weight: normal;
      color: black;
    }
  }
}
