@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.nav-bar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #1c1c1c;
  z-index: 60;
  @include font-400();
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: $MOBILE-NAVBAR-LARGE-WIDTH;
  font-size: 12px;
  border-bottom: 5px solid $BASE-RED-COLOR;
  padding: 0 20px;

  &-user {
    width: 30px;
    height: 30px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid white;

    &:hover {
      border-color: $BASE-RED-COLOR;
    }

    h2 {
      color: $BASE-RED-COLOR;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  &-user-dropdown {
    cursor: unset;
    .ant-dropdown-menu-item {
      cursor: unset !important;
    }
    .ant-dropdown-menu-item {
      background: white !important;
    }
    p {
      @include reduceText(220px);
    }
  }

  &--logo {
    width: 140px;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 10px;
  }

  &--item {
    @include flex-center();
    height: 65px;
    padding: 0 10px;

    &-logout {
      svg {
        height: 16px;

        path {
          fill: white;
        }
      }
    }
  }

  &--dropdown-item {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-decoration: unset;
    text-transform: capitalize;
  }

  &--dropdown-hidden {
    height: 0;
    overflow: hidden;
    position: absolute;
  }

  &--dropdown-visible {
    position: absolute;
    top: 58px;
    text-decoration-color: unset;
    list-style-type: none;
    padding: 16px;
    background-color: #303030;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    z-index: 1
  }

  &--menu-item {
    cursor: pointer;
    display: flex;
    gap: 6px;
    align-items: center;

    &-icon {
      fill: white;
      &-rotated {
        fill: white;
        transform: rotate(180deg)
      }
    }
  }
  &--item {
    position: relative;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background: $BASE-RED-COLOR;
    }

    &-name {
      color: white;
      text-transform: uppercase;
      text-align: center;

      .report-count {
        border-radius: 15px;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        background: $BASE-BACKGROUND-COLOR;
        top: 10px;
        right: -2px;
        padding: 5px;
        color: $HEADER-BACKGROUND-COLOR;
        font-weight: 700;
        font-size: $SMALL-FONT-SIZE;
        text-align: center;
      }
    }
  }

  &--item-active {
    position: relative;
    background: $BASE-RED-COLOR;

    .report-count {
      // border-radius: 15px;
      // display: block;
      // width: 20px;
      // height: 20px;
      // position: absolute;
      // background: $BASE-RED-COLOR;
      // top: 2px;
      // right: 15px;
      // padding: 5px;
      // color: white;
      // font-size: $SMALL-FONT-SIZE;
      // text-align: center;
    }
  }
}
