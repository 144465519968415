@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.base-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: $BASE-FONT-SIZE;
  color: $BASE-TEXT-COLOR;

  &--label {
    margin: 10px 0;
    opacity: 0.8;
  }
  &--input {
    display: none;
  }

  &--wrapper {
    position: relative;
  }
  &--select {
    overflow: hidden;
    position: relative;
    @include form-styles();
    max-height: 40px;
    background-color: $BASE-BACKGROUND-COLOR;
    cursor: pointer;
    &-error {
      border: 1px solid $BASE-RED-COLOR;
    }
  }
  &--arrow {
    position: absolute;
    top: 18px;
    right: 10px;
  }
  &--select-open {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $BASE-RED-COLOR_FOCUS;
    //border-bottom: 1px transparent;
  }
  &--content {
    position: absolute;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    display: none;
  }
  &--content-open {
    border: 1px solid $BASE-RED-COLOR_FOCUS;
    border-top: none;
    display: block;
  }
  &--options {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 0;
    background-color: $BASE-BACKGROUND-COLOR;
  }
  &--options-open {
    height: 100%;
    max-height: 165px;
    overflow: auto;
  }
  &--option {
    display: flex;
    align-items: center;
    padding: 0 18px;
    min-height: 40px;
    background-color: $BASE-WHITE-COLOR;
    &:hover {
      background-color: $BASE-BACKGROUND-COLOR;
      cursor: pointer;
    }
  }
  .input {
    background-color: $BASE-BACKGROUND-COLOR !important;
    width: 90%;
    font-size: $BASE-FONT-SIZE;
  }
}
