@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.event-hashtags {
  flex: 2;
  background-color: $BASE-BACKGROUND-COLOR;
  padding: 24px;
  position: relative;
  &--input-block {
    display: flex;
    gap: 40px;
  }
}
