@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.venue-manager {
  flex: 1;
  min-height: 100%;
  max-height: 710px;
  background-color: $BASE-BACKGROUND-COLOR;
  padding: 12px;
  overflow: auto;
  position: relative;
  @include scroll-bar;

  &--content {
    @media screen and (max-width: 1800px) {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 4px;
    }
  }

  &--title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 22px;
    @media screen and (max-width: $large) {
      font-size: 10px;
      p {
        font-size: 10px;
      }
    }
  }

  &--block {
    border: 1px solid $BASE-WHITE-COLOR;
    padding: 10px;
    border-radius: 9px;
    margin-bottom: 10px;
  }

  &--data {
    @include line-clamp(1);
    min-width: 300px;
    margin-top: 12px;
    margin-bottom: 18px;
    background-color: $BASE-WHITE-COLOR;
    border-radius: 30px;
    padding: 13px 26px 11px;
    @media screen and (max-width: 400px) {
      font-size: 12px;
      min-width: 200px;
      max-width: 238px;
    }
  }
}
