@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.contact-view-modal {
  width: 30vw;
  max-width: 400px;
  &--content {
    @include flex-center();
    flex-direction: column;
    padding: 10px;
  }
  &--title {
    font-size: 24px;
    margin-bottom: 30px;
    color: $BASE-RED-COLOR;
    text-align: center;
  }
  &--btn-block {
    display: flex;
    justify-content: center;
    gap: 75px;
  }
  &--cancel-btn {
    background-color: $GRAY-COLOR;
  }
  &--list-block {
    list-style-type: none;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &--list-segment {
    text-transform: capitalize;
  }
}
