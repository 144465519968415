@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.breadcrumb {
  &--header {
    display: flex;
    align-items: center;
    gap: 14px;
    text-transform: uppercase;
    background: white;
    padding: 20px;
    position: sticky;
    top: 70px;
    left: 0;
    z-index: 59;
    @media screen and (max-width: $medium) {
      top: 50px;
    }
    &--back {
      cursor: pointer;
    }
  }
}
