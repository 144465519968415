.admin-page {
  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  &--modal {
    width: 40vw;
    max-width: 50vw;
  }
}
