@import "src/assets/styles/variables";

.event-date-range {
  display: flex;
  justify-content: space-between;
  .input-field-distance {
    width: 48%;
  }
}

.event-color-options {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 12px;
}

.ticket-sales-title {
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 16px;
}
