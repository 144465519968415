.staff-page {
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  &--container {
    height: 340px;
    overflow: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }
  &--modal {
    width: 40vw;
    max-width: 50vw;
  }
}
