@import "src/assets/styles/variables";

.venue-info-form {
  flex: 1;
  &--social-block {
    width: 100%;
    display: flex;
    gap: 20px;

    &-select {
      min-width: max-content;
    }
    &-input {
      width: 100%;
    }
  }
  &--input-block {
    min-height: 92px;
    max-height: 92px;
  }
  .base-select {
    margin-bottom: unset;
  }
}
