@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.ticket-view {
  @include base-view;

  &--edit {
    @include base-view-edit-btn;
  }
}

.divider {
  width: inherit;
  height: 1px;
  background-color: $BASE-BACKGROUND-COLOR;
  margin: 20px 0px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
