@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.base-alert {
  @include flex-center();
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  min-width: 200px;
  height: 40px;
  padding: 20px;
  color: $BASE-WHITE-COLOR;
  animation-name: alert;
  animation-duration: 4s;
  opacity: 0;
  z-index: 999;
  &--success {
    background-color: green;
  }
  &--failed {
    background-color: red;
  }
}

@keyframes alert {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
