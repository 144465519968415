@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.venue-days-form {
  position: relative;
  flex: 1;
  &--error {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    color: $BASE-RED-COLOR;
  }
  &--days {
    @include font-500();
    text-align: center;
    font-size: 12px;
    width: 110px;
    height: 40px;
    margin-bottom: 4px;
    border-radius: 30px;
    opacity: 0.8;
    color: rgba(230, 51, 53, 0.5);
    background-color: $BASE-BACKGROUND-COLOR;
  }
  &--content {
    @include flex-center();
    gap: 15px;
    flex-wrap: wrap;
    padding-top: 16px;
    @media screen and (max-width: $small) {
      flex-direction: column;
      margin-bottom: 20px;
      gap: 0;
    }
    &--block {
      display: flex;
      align-items: center;
      gap: 15px;
      @media screen and (max-width: $small) {
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
      }
      .base-input--error,
      .base-select--label {
        display: none;
      }
    }
    .base-select--select {
      font-size: 12px;
      width: 110px;
    }
    .base-select--option {
      font-size: 12px;
    }
  }
  &--checkbox {
    display: none;
    background-color: blue;
  }
  &--custom-checkbox {
    @include flex-center();
    margin-bottom: 4px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    background-color: $BASE-BACKGROUND-COLOR;
    &--checked {
      display: none;
      width: 9px;
      height: 9px;
      background-color: $BASE-RED-COLOR;
      border-radius: 50%;
    }
  }

  input[type="checkbox"]:checked
    + .venue-days-form--custom-checkbox
    > .venue-days-form--custom-checkbox--checked {
    display: block;
  }

  input[type="checkbox"]:checked ~ .venue-days-form--days {
    opacity: 1;
    color: $BASE-RED-COLOR;
  }

  .base-input {
    &--input {
      &:focus {
        border: 1px solid transparent !important;
      }
    }
  }
}
