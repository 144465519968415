.admin-settings {
  background-color: #ffffff;

  .content {
    display: flex;
    flex: 1;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    height: 100%;
    padding: 40px 32px;

    .base-select--label {
      font-size: 16px;
      font-weight: 400;
    }

    .base-select--select {
      width: 304px;
    }

    .venue_viewer {
      display: flex;
      flex-direction: row;
      gap: 16px;
      &_enable {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #303030;
      }
    }
  }
}

.ant-switch {
  background: #e8ebf1 !important;
  padding: 0 !important;
}

.ant-switch-checked {
  background: #e8ebf1 !important;
  padding: 0 !important;
  .ant-switch-handle {
    &::before {
      background-color: #e0e620 !important;
      box-shadow: none !important;
    }
  }
  .ant-switch-inner {
    // background-color: #e8ebf1 !important;
  }
}
