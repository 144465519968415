@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.images-container {
  position: relative;

  span {
    text-align: center;
  }

  &--transbox {
    display: flex;
    flex-wrap: wrap;
  }

  &--delete-svg {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    z-index: 10;

    path {
      fill: $BASE-WHITE-COLOR;
    }

    &:hover {
      path {
        fill: $BASE-RED-COLOR;
      }
    }
  }

  &--item {
    position: relative;
    margin: 1px;
    display: inline-block;
    width: calc((100% / 4) - 8px);
    height: 0;
    padding-bottom: calc((100% / 4) - 4px);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    @media screen and (max-width: $large) {
      width: calc((100% / 3) - 8px);
      padding-bottom: calc((100% / 3) - 4px);
    }
    @media screen and (max-width: 700px) {
      width: calc((100% / 2) - 4px);
      padding-bottom: calc((100% / 2) - 4px);
    }
    @media screen and (max-width: 500px) {
      width: calc(100% - 4px);
      padding-bottom: calc(100% - 4px);
    }

    &-venue-info {
      @include flex-center();
      flex-direction: column;
      margin-top: 40px;
      width: 100%;
      height: calc(100% - 40px);
      position: absolute;
      font-size: 24px;
      font-weight: 600;
      color: $BASE-WHITE-COLOR;

      &--name {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 20px;
        @include reduceText(100%);
      }

      &--rating {
        margin-top: 5px;
        display: flex;
        gap: 4px;
      }

      @media screen and (max-width: $large) {
        font-size: 20px;
      }
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        font-size: 24px;
      }
    }

    &-event-info {
      @include flex-center();
      flex-direction: column;
      margin-top: 10px;
      width: 100%;
      height: calc(100% - 20px);
      position: absolute;
      font-size: 24px;
      font-weight: 600;
      color: $BASE-WHITE-COLOR;

      &--date {
        margin-bottom: 13px;
        font-style: italic;
        font-size: 18px;
      }

      &--name {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 20px;
        @include reduceText(100%);
      }

      &-time {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 18px;
        line-height: 0;
      }

      @media screen and (max-width: $large) {
        font-size: 20px;
      }
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        font-size: 24px;
      }
    }

    &-news-info {
      @include flex-center();
      flex-direction: column;
      gap: 30px;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      font-size: 24px;
      color: $BASE-WHITE-COLOR;

      &--titles {
        @include flex-center();
        text-align: center;
        width: 100%;
        gap: 10px;
        flex-direction: column;
        z-index: 2;
      }

      &--name {
        width: 100%;
        padding: 0 20px;
        @include line-clamp(2);
      }

      &--hashtags {
        @include flex-center();
        max-width: 90%;
        font-size: 12px;
        flex-wrap: wrap;
        z-index: 2;
        border-radius: 10px;
        color: $BASE-WHITE-COLOR;
        background: rgba(33, 33, 33, 0.8);
      }

      &--hashtag {
        @include ellipsis-text();
        text-align: center;
        width: 70px;
        max-width: 70px;
        padding: 6px 12px;
        margin: 4px;
        border: 1px solid $BASE-WHITE-COLOR;
        border-radius: 50px;
        z-index: 2;
      }

      &--hashtag--more {
        font-size: 24px;
      }

      &--inactive {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(33, 33, 33, 0.8);
      }

      @media screen and (max-width: $large) {
        font-size: 20px;
      }
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        font-size: 24px;
      }
    }
  }
}

.base-responsive-table {
  min-width: 1150px;

  &-mobile {
    @media screen and (max-width: $large-l) {
      min-width: initial;
    }
  }
  &-id {
    width: 30px;
  }

  &-name {
    max-width: 135px;
    span {
      color: darkgrey;
    }
  }

  &-image {
    width: 70px;
    background: #f5f5f5;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-description {
    @include reduceText(200px);
  }

  &-streamUrl {
    @include reduceText(100px);
  }
  &-rate {
    svg {
      width: 15px;
      height: 23px;

      path {
        fill: $BASE-RED-COLOR;
      }
    }
  }

  &-actions {
    button {
      padding: 5px 10px;
      color: white;
      //background: $BASE-RED-COLOR;
      cursor: pointer;
    }
  }

  .ant-pagination {
    justify-content: flex-start;
    margin-top: 30px !important;

    .ant-pagination-item-active {
      border-color: #1c1c1c;

      a {
        color: $BASE_RED-COLOR;
      }
    }
  }
}
