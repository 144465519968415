@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.reported-post-page {
  background: $BASE-WHITE-COLOR;
  margin-bottom: 30px;
  padding: 36px 40px 45px;
  position: relative;
  &-header {
    margin-bottom: 30px;
    &-title {
      @include main-title;
      margin: 0 10px 26px 0;
      font-size: 18px;
    }
    &-date {
      margin-right: 18px;
      color: $BASE-LIGHT-TEXT-COLOR;
      font-weight: 300;
      font-style: italic;
    }
    &-reports-count {
      color: rgba(57, 57, 57, 0.49);
      .reports-count {
        margin: 0 8px;
        font-style: italic;
        color: $BASE-RED-COLOR;
      }
    }
  }
  &-content {
    display: flex;
    @media screen and (max-width: $large) {
      flex-direction: column;
    }
    @media screen and (max-width: $small) {
      flex-direction: column;
    }
    &-reasons {
      flex-basis: 417px;
      @media screen and (max-width: $large-l) {
        flex-basis: 330px;
      }
      @media screen and (max-width: $large) {
        flex-basis: 100%;
      }
      @media screen and (max-width: $small) {
        flex-basis: 100%;
      }
      &-title {
        color: $BASE-LIGHT-TEXT-COLOR;
        font-size: 16px;
        margin-bottom: 18px;
        font-weight: 400;
      }
      &-types {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;
        &-item {
          color: $BASE-RED-COLOR;
          padding: 12px 24px;
          font-size: $BASE-FONT-SIZE;
          font-weight: 500;
          background: #f5f5f5;
          border-radius: 50px;
          margin: 0 20px 20px 0;
          text-transform: capitalize;
        }
      }
      &-others {
        &-title {
          color: rgba(57, 57, 57, 0.7);
          font-size: $BASE-FONT-SIZE;
          margin-bottom: 12px;
        }
        &-reports {
          max-height: 400px;
          overflow: auto;
          @include scroll-bar;
          &-item {
            width: 90%;
            padding: 13px 26px;
            border-radius: 25px;
            margin-bottom: 22px;
            background: rgba(239, 239, 239, 0.6);
            @media screen and (max-width: $large) {
              width: 100%;
            }
            &-message {
              margin-bottom: 24px;
              font-size: $BASE-FONT-SIZE;
              color: rgba(57, 57, 57, 0.7);
            }
            &-footer {
              text-align: right;
              .reporter-name {
                color: rgba(57, 57, 57, 0.88);
                font-style: italic;
                margin-bottom: 4px;
              }
              .reporter-date {
                font-weight: 300;
              }
            }
          }
        }
      }
    }
    &-post-details {
      margin: 80px 0 0 80px;
      background: #393939;
      border-radius: 10px;
      background: rgba(239, 239, 239, 0.6);
      flex-basis: calc(100% - 417px);
      padding: 35px 38px;
      @media screen and (min-width: $large-xl) {
        flex-basis: calc(100% - 417px);
      }
      @media screen and (max-width: $large-l) {
        margin: 0;
        flex-basis: calc(100% - 330px);
        padding-left: 80px;
      }
      @media screen and (max-width: $large) {
        flex-basis: 100%;
        padding: 22px 30px 60px 22px;
      }
      @media screen and (max-width: $small) {
        flex-basis: 100%;
      }
      &-title {
        @include main-title;
        font-size: 18px;
        margin: 0 18px 26px 0;
      }
      &-media {
        margin: 20px 0;
        &-title {
          color: rgba(57, 57, 57, 0.88);
          margin-bottom: 4px;
        }
      }
      &-description,
      &-autor {
        margin-bottom: 10px;
      }
      &-description {
        margin-bottom: 22px;
        &-label {
          margin-bottom: 12px;
        }
        &-text {
          min-height: 45px;
          width: 360px;
          background: white;
          border-radius: 20px;
          padding: 13px 26px;
        }
      }
      &-author {
        &-label {
          margin-bottom: 12px;
        }
        &-name {
          min-height: 45px;
          width: 360px;
          background: white;
          border-radius: 20px;
          padding: 13px 26px;
        }
      }
    }
  }
  &-button {
    margin-top: 20px;
    @include flex-center();
    .ignore-button {
      text-transform: uppercase;
      width: inherit;
      padding: 0 24px;
    }
    .delete-button {
      text-transform: uppercase;
      margin-left: 20px;
      width: inherit;
      padding: 0 24px;
    }
  }
}
