@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.base-layout {
  display: flex;
  flex-direction: column;
  @include font-400();
  height: 100%;
  color: $BASE-TEXT-COLOR;

  @media screen and (max-width: $medium) {
    flex-direction: column;
  }

  &--back-to-login {
    @include center-block();
    top: 60%;
    width: 200px;
  }
}
