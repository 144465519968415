@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.venue-info {
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: rgba(239, 239, 239, 0.5);
  padding: 24px;
  gap: 40px;
  @media screen and (max-width: 1124px) {
    align-items: center;
    flex-direction: column;
    gap: 0;
  }

  &--title {
    font-size: 14px;
    margin-bottom: 12px;
    @media screen and (max-width: $large) {
      font-size: 10px;
    }
  }

  &--block {
    width: 100%;
    font-size: 14px;
  }
}
