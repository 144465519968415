@import "src/assets/styles/variables";

@mixin center-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin modal-style {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 35, 0.54);
}

@mixin font-400 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
  font-family: "Roboto", sans-serif !important;
}

@mixin font-500 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");
  font-family: "Roboto", sans-serif !important;
}

@mixin table-content {
  display: flex;
  align-items: center;
  font-size: 14px;
}

@mixin ellipsis-text {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
  padding: 0 20px;
}

@mixin line-clamp($clamp) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin reduceText($width) {
  text-overflow: ellipsis;
  overflow: hidden;
  width: $width;
  white-space: nowrap;
}

@mixin main-title {
  text-transform: uppercase;
  font-size: $BASE-FONT-SIZE;
  font-weight: 700;
  color: $BASE-RED-COLOR;
  margin-bottom: 10px;
}

@mixin form-styles {
  padding: 0 18px;
  line-height: 38px;
  border-radius: 86px;
  border: 1px solid transparent;
  background-color: $BASE-WHITE-COLOR;
  color: $BASE-TEXT-COLOR;
}

@mixin form-error {
  padding: 4px 0;
  display: block;
  margin-left: 18px;
  height: 16px;
  font-size: 12px;
  color: $BASE-RED-COLOR;
}

@mixin scroll-bar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5 !important;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: #f5f5f5 !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-clip: padding-box;

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #c0c0c0 !important;
  }
}

@mixin white-btn {
  color: $BASE-RED-COLOR;
  border: 1px solid $BASE-RED-COLOR;
  background-color: $BASE-WHITE-COLOR;
  &:hover {
    color: $BASE-WHITE-COLOR;
    background-color: $BASE-RED-COLOR;
  }
}

@mixin base-view {
  background-color: $BASE-WHITE-COLOR;
  position: relative;
  min-height: 100%;
  padding: 40px;
  @media screen and (max-width: $large) {
    padding: 10px;
  }
}

@mixin base-view-edit-btn {
  position: absolute;
  top: 36px;
  right: 50px;
  cursor: pointer;
  path {
    fill: $BASE-RED-COLOR;
  }
  @media screen and (max-width: $large) {
    top: 10px;
    right: 20px;
  }
}

@mixin base-view-name {
  font-size: 18px;
  padding-bottom: 26px;
  color: $BASE-RED-COLOR;
}

@mixin base-view-top-block {
  display: flex;
  justify-content: flex-start;
  gap: 70px;
  @media screen and (max-width: 1800px) {
    flex-direction: column;
  }
}

@mixin base-view-bottom-block {
  margin-top: 80px;
  &--title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 22px;
  }
}

@mixin reduceText($width) {
  text-overflow: ellipsis;
  overflow: hidden;
  width: $width;
  white-space: nowrap;
}

@mixin margin($size, $position) {
  .m#{str-slice($position, 0, 1)}-#{$size} {
    margin-#{$position}: $size + px;
  }
  .m-#{$size} {
    margin: $size + px;
  }
  .mx-#{$size} {
    margin: 0 $size + px;
  }
  .my-#{$size} {
    margin: $size + px 0;
  }
}

@mixin position($value) {
  position: $value;
}

@mixin padding($size, $position) {
  .p#{str-slice($position, 0, 1)}-#{$size} {
    padding-#{$position}: $size + px !important;
  }
  .p-#{$size} {
    padding: $size + px;
  }
  .px-#{$size} {
    padding: 0 $size + px;
  }
  .py-#{$size} {
    padding: $size + px 0;
  }
}

@mixin display($value) {
  display: $value;
}

@mixin flex-direction($value) {
  @if $value== "col" {
    flex-direction: column;
  } @else if $value== "col-reverse" {
    flex-direction: column-reverse;
  } @else {
    flex-direction: $value;
  }
}

@mixin align-items($value) {
  @if $value==start or $value==end {
    align-items: flex-#{$value};
  } @else {
    align-items: $value;
  }
}

@mixin justify-content($value) {
  @if $value==start or $value==end {
    justify-content: flex-#{$value};
  } @else if $value==between or $value==around {
    justify-content: space-#{$value};
  } @else {
    justify-content: $value;
  }
}

@mixin scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 10px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
