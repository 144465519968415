@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.user-layout {
  flex-grow: 2;
  margin-top: 35px;
  padding: 0 30px;

  @media screen and (max-width: $medium) {
    padding: 0 8px;
  }
}
